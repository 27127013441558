<template>
  <v-main v-if="lvl == 1" class="ma-0 pa-0">
    <v-container fluid class="text-right ma-0 pa-0">
      <v-row>
        <v-col>
          <v-dialog v-model="dialog" max-width="400px">
            <template v-slot:activator="{ on }">
              <v-btn class="mx-3 ma-0" color="white" fab v-on="on" elevation=0><v-icon color="#ab1d66" x-large>add</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.name" class="right-input" label="품명" />
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="q1" item-text="title" item-value="value" v-model="editedItem.q1" class="right-input" label="우유 한번에 500ml 마시면 배아픔" />
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="q2" item-text="title" item-value="value" v-model="editedItem.q2" class="right-input" label="트러블이 덜한 것(단, 맛이 없음)" />
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="q3" item-text="title" item-value="value" v-model="editedItem.q3" class="right-input" label="맛(맛있을수록 첨가물이 많음)" />
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="q4" item-text="title" item-value="value" v-model="editedItem.q4" class="right-input" label="천연감미료(스테비아)" />
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="q5" item-text="title" item-value="value" v-model="editedItem.q5" class="right-input" label="운동후 식사 30분 이내 가능(나눠먹기로 커버 가능)" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.note" class="right-input" label="가격" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.comment" class="right-input" label="코멘트" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" class="white--text" small @click="close">취소</v-btn>
                <v-btn color="blue darken-1" class="white--text" small @click="save">저장</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-card class="pa-3 ma-2 text-left" flat>
        <v-row dense>
          <v-col cols="3" >
            <div class="headline font-weight-bold pa-1 pb-0">보충제 선택</div>
            <div class="subtitle caption pa-1">
              <div class="desc_title">
                보충제 선택 관리자 화면
              </div>
            </div>
          </v-col>
          <v-col cols="9" >

            <v-container fluid v-if="loading">
              <v-row dense>
                <v-col cols="12">
                  <v-row class="align-center justify-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-card v-for="(each, idx) in list" :key="each._id"  outlined class="body-2 mb-2 pa-2 pt-1 pb-1 caption">
              <v-row dense >
                <v-col cols="10">
                  {{ each.name }}
                </v-col>
                <v-col cols="2">
                  <v-btn icon color="black" small @click="edit_supp(idx, each)">
                    <v-icon color="blue" small>edit</v-icon>
                  </v-btn>
                  <v-btn icon color="black" small @click="del_supp(each._id)">
                    <v-icon color="red" small>mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <span class="caption">1번: {{ each.q1 }}</span>
                </v-col>
                <v-col cols="2">
                  <span class="caption">2번: {{ each.q2 }}</span>
                </v-col>
                <v-col cols="2">
                  <span class="caption">3번: {{ each.q3 }}</span>
                </v-col>
                <v-col cols="2">
                  <span class="caption">4번: {{ each.q4 }}</span>
                </v-col>
                <v-col cols="2">
                  <span class="caption">5번: {{ each.q5 }}</span>
                </v-col>
                <v-col cols="2">
                  <div v-html="each.note" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <div v-html="each.comment" />
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import '../../common/common.js';

import { mapGetters } from 'vuex';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      loading: true,
      dialog: false,
      list: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        note: 0,
        comment: 0,
      },
      defaultItem: {
        name: '',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        note: 0,
        comment: 0,
      },
      // 우유 한번에 500ml 마시면 배아픔
      q1 : [
        { title: "Y", value: 1 },
        { title: "N", value: 2 },
        { title: "SoSo", value: 3 },
      ],
      // 트러블이 덜한 것 ( 단, 맛이 없음 )
      q2 : [
        { title: "Y", value: 1 },
        { title: "상관없음", value: 2 },
      ],
      // 맛(맛있을수록 첨가물이 많음)
      q3 : [
        { title: "보통이면됨", value: 1 },
        { title: "맛있어야함", value: 2 },
        { title: "상관없음", value: 3 },
      ],
      // 천연감미료(스테비아)
      q4 : [
        { title: "Y", value: 1 },
        { title: "N", value: 2 },
      ],
      // 운동후 식사 30분 이내 가능(나눠먹기로 커버 가능)
      q5 : [
        { title: "Y", value: 1 },
        { title: "N", value: 2 },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",

    })
  },
  created: async function() {
    this.loading = true;
  },
  mounted: async function() {
    this.load_info();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    load_info: async function() {
      if ( firebase.auth().currentUser ) {
        // 이전 정보가 있는지 확인
        const id_token = await firebase.auth().currentUser.getIdToken();
        const supps_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/choice/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if (supps_.data.length>0) {
          this.list = supps_.data;
        }

        this.loading = false;
      } else {
        setTimeout(this.load_info, 500);
      }

    },
    // del_chicken: async function(_id) {

    //   let index = 0;
    //   for ( let each of this.list ) {
    //     if ( each._id == _id ) {
    //       break;
    //     }
    //     index++;
    //   }

    //   confirm('삭제하시겠습니까?') && this.list.splice(index, 1);

    //   const id_token = await firebase.auth().currentUser.getIdToken(true);
    //   await axios.delete(
    //     api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
    //       data: {
    //         email: this.user.data.email,
    //         chick_id: _id,
    //       },
    //       headers: {'id_token': id_token},
    //   });

    //   // this.move();
    // },
    edit_supp: async function(idx, item) {

      this.editedIndex = idx;

      this.editedItem = item;
      //this.editedItem.category = item.category;

      // console.log(this.editedItem.category);
      // console.log(this.categories);

      this.dialog = true;
    },
    close: function () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save: async function () {

      if (this.editedIndex > -1) {
        Object.assign(this.list[this.editedIndex], this.editedItem)
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.put(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/choice/', {
          email: this.user.data.email,
          _id: this.editedItem._id,
          data: this.editedItem,
        }, {
          headers: {'id_token': id_token},
        });
      } else {
        this.list.push(this.editedItem)
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/choice/', {
          email: this.user.data.email,
          supplement: this.editedItem,
        }, {
          headers: {'id_token': id_token},
        });
      }

      this.close()
    },
    deleteItem: async function (item) {
      const index = this.list.indexOf(item)

      const del_id = this.list[index]._id;

      confirm('삭제하시겠습니까?') && this.list.splice(index, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/choice/', {
          data: {
            email: this.user.data.email,
            supp_id: del_id,
          },
          headers: {'id_token': id_token},
      });
    },

  }
}
</script>

<style scoped>


.desc {
  margin-top:10px;
}

.headline {
  font-size: 1.3rem !important;
}

.desc_title {
  font-size:1.1em;
}

</style>
