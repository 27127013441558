/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

Number.prototype.format = function(){
  if(isNaN(this)) return 0;
  if(this==0) return 0;

  var reg = /(^[+-]?\d+)(\d{3})/;
  var n = (this + '');

  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

  return n;
};